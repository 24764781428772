exports.components = {
  "component---src-pages-404-jsx": () => import("./../../../src/pages/404.jsx" /* webpackChunkName: "component---src-pages-404-jsx" */),
  "component---src-pages-blog-jsx": () => import("./../../../src/pages/blog.jsx" /* webpackChunkName: "component---src-pages-blog-jsx" */),
  "component---src-pages-bon-online-jsx": () => import("./../../../src/pages/bon-online.jsx" /* webpackChunkName: "component---src-pages-bon-online-jsx" */),
  "component---src-pages-cancelled-pay-jsx": () => import("./../../../src/pages/cancelled-pay.jsx" /* webpackChunkName: "component---src-pages-cancelled-pay-jsx" */),
  "component---src-pages-cennik-jsx": () => import("./../../../src/pages/cennik.jsx" /* webpackChunkName: "component---src-pages-cennik-jsx" */),
  "component---src-pages-index-jsx": () => import("./../../../src/pages/index.jsx" /* webpackChunkName: "component---src-pages-index-jsx" */),
  "component---src-pages-konsultacja-jsx": () => import("./../../../src/pages/konsultacja.jsx" /* webpackChunkName: "component---src-pages-konsultacja-jsx" */),
  "component---src-pages-kontakt-jsx": () => import("./../../../src/pages/kontakt.jsx" /* webpackChunkName: "component---src-pages-kontakt-jsx" */),
  "component---src-pages-o-nas-jsx": () => import("./../../../src/pages/o-nas.jsx" /* webpackChunkName: "component---src-pages-o-nas-jsx" */),
  "component---src-pages-polityka-prywatnosci-jsx": () => import("./../../../src/pages/polityka-prywatnosci.jsx" /* webpackChunkName: "component---src-pages-polityka-prywatnosci-jsx" */),
  "component---src-pages-success-pay-jsx": () => import("./../../../src/pages/success-pay.jsx" /* webpackChunkName: "component---src-pages-success-pay-jsx" */),
  "component---src-pages-szkolenia-jsx": () => import("./../../../src/pages/szkolenia.jsx" /* webpackChunkName: "component---src-pages-szkolenia-jsx" */),
  "component---src-pages-tradzik-jsx": () => import("./../../../src/pages/tradzik.jsx" /* webpackChunkName: "component---src-pages-tradzik-jsx" */),
  "component---src-templates-blog-post-template-jsx": () => import("./../../../src/templates/blog-post-template.jsx" /* webpackChunkName: "component---src-templates-blog-post-template-jsx" */)
}

